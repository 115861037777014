/*! main.js | Brilliantec | 2023 */

/* ==========================================================================
Core JS file
========================================================================== */

"use strict";

//1. Preload page
initPageLoader()

$(document).ready(function ($) {

	//2. Lazy loading
	const el = document.querySelectorAll('[data-lazy-load]');
    const observer = lozad(el, {
        loaded: function(el) {
            // Custom implementation on a loaded element
            el.parentNode.classList.add('loaded');
        }
	});
	
  observer.observe();

	//3. Change to demo content (if env)
	if (env === 'development') {
		changeDemoImages();
	}

	//4. Init Feather icons
	feather.replace();

	//5. Init Layout
	initNavbar();
	initLandingNavbar();
	initMobileMenu();
	initLandingMobileMenu();
	initEcommerceNavbar();
	initNavbarDropdown();
	initSidebar();
	initBackgroundImages();

	//6. Components
	initSlider();
	initDropdowns();
	initTabsNav();
	initNavigationTabs();
	initVerticalTabs();
	initMediaCards();
	initTiltCards();
	initPopovers();
	initTooltips();
	initModals();
	initCounters();
	initSimpleAccordion();
	initAccordions();
	initToasts();
	initCountdown();

	//7. Carousels
	initBasicCarousel();
	initVerticalCarousel();
	initFlatCarousel();
	initImageCarousel();
	initSingleImageCarousel();
	initMultipleImagesCarousel();
	
	//8. Forms
	initDatepicker();
	initTimepicker();
	initDatepickerAlt();
	initChosenSelects();
	initMaterialSelect();
	initAutocompletes();
	initFileInputs();
	initRangeInput();
	initRangeInputs();
	initJqueryTagInput();
	initBulmaTags();
	initBulmaSteps();
	initBulmaIconpicker();
	initBulmaCalendar();
	initComboBox();
	initImageComboBox();
	initStackedComboBox();
	initFileUploader();
	
	//9. Video
	initVideoEmbed();
	initBackgroundVideo();
	initPlayers();
	
	//10. Demo
	initDemo();
	initScrollspyNav();
	initParallax();
	initBackToTop();
	
	//11. Utility functions
	initGitem();
	initAnchorScroll();
	initQuickview();
	initScrollReveal();
	initMarquee();
	
	//12. Page specific methods
	initMockup();
	initClientsCarousel();
	initPeopleCarousel();
	initCustomCarousel();
	initCarousel();
	initLandingCarousel();
	initTestimonials();
	initCharacterTestimonials();
	initPricing();
	initPricingCarousel();
	initTabbedPricing();
	initFreelancerPricing();
	initSwitchPricing();
	initBoxedPricing();
	initOnePagePricing();
	//initBlog();
	initSearchBox();
	initNavigationDots();
	initFaq();
	initAuth();
	initAnimations();
	initCanvas();
	initParticles();
	initAnimatedSvg();
	initChatWidget();
	initContactToggler();
	initCodeTabs();
	if($('.contact-section').length>0)
	{
		$('.navbar-item').removeClass('is-active');
   		$('#contact-page').addClass('is-active');
	}
	else if($('.about-section').length>0)
	{
		$('.navbar-item').removeClass('is-active');
   		$('#about-page').addClass('is-active');
	}
	var origin= window.location.origin;
	var pathname= window.location.pathname;
	var currentLang='en';
	if(pathname.indexOf('/jp')!==-1)
	{
		currentLang='jp';
	}
	else if(pathname.indexOf('/cn')!==-1)
	{
		currentLang='cn';
	}
		$("#product-page").click(function() {
			if($('#product-section').length>0)
			{
			    $('html,body').animate({
			        scrollTop: $("#product-section").offset().top-50},
			        800);
			}
			else{
				if(currentLang=='jp')
				{
					window.location.href="/jp/#product-section"
				}
				else if(currentLang=='cn')
				{
					window.location.href="/cn/#product-section"
				}
				else{
					window.location.href="/#product-section"
				}
			}
		});
	

	
		$("#development-page").click(function() {
			if($('#development-section').length>0)
			{
			    $('html,body').animate({
			        scrollTop: $("#development-section").offset().top-50},
			        800);
			}
			else{
				if(currentLang=='jp')
				{
					window.location.href="/jp/#development-section";
				}
				else if(currentLang=='cn')
				{
					window.location.href="/cn/#development-section"
				}
				else{
					window.location.href="/#development-section"
				}
			}
		});
		$('.language-toggle').click(function() {

			var lang= $(this).attr('data-lang');
			var origin= window.location.origin;
			var pathname= window.location.pathname;
			var url='';
			if(lang=='en')
			{
				url=origin+pathname.replace('/jp','');
				url=url.replace('/cn','');
			}
			else if(lang=='cn' && pathname.indexOf('/cn')=== -1)
			{
				url=origin+'/cn'+pathname.replace('/jp','');
			}
			if(lang=='jp' && pathname.indexOf('/jp')=== -1)
			{
				url=origin+'/jp'+pathname.replace('/cn','');
			}

			if(url)
			{
				window.location.href=url
			}
		})
		$('#submit-contact').click(function(){
		  	var data=$(this).serializeArray(); 
		  	$('.name').removeClass('border-danger');
		  	$('.email').removeClass('border-danger');
		  	$('.phone').removeClass('border-danger');
		  	$('.message-box').removeClass('border-danger');
		  	$('.res-message').html('');
		  	$('.contact-message').html('');
		  	var valid=true;
	  		var name=$('.name').val();
	  		var email=$('.email').val();
	  		var phone=$('.phone').val();
	  		var message=$('.message-box').val();

	  		var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
	  		var isSubmit=false;
	  		if(name)
	  		{
	  			if(name.length<3){
	  				$('.name').addClass('border-danger'); valid=false;
		  			if(currentLang=='en')
		  			{
		  				$('.contact-message').html('Your name must have a minimum of 3 characters.')
		  			}
		  			if(currentLang=='jp')
		  			{
		  				$('.contact-message').html('あなたの名前は少なくとも 3 文字である必要があります。')
		  			}
		  			if(currentLang=='cn')
		  			{
		  				$('.contact-message').html('您的姓名必须至少包含 3 个字符。')
		  			}
		  		}
		  		else if(name.length >100){
		  			$('.name').addClass('border-danger'); valid=false;
		  			if(currentLang=='en')
		  			{
		  				$('.contact-message').html('Your name must have a maximum of 100 characters.')
		  			}
		  			if(currentLang=='jp')
		  			{
		  				$('.contact-message').html('名前は最大 100 文字にする必要があります。')
		  			}
		  			if(currentLang=='cn')
		  			{
		  				$('.contact-message').html('您的姓名不得超过 100 个字符。')
		  			}
		  		}
	  		}
	  		else{
	  			$('.name').addClass('border-danger'); valid=false;
	  			if(currentLang=='en')
	  			{
	  				$('.contact-message').html('Your name is required.')
	  			}
	  			if(currentLang=='jp')
	  			{
	  				$('.contact-message').html('お名前は必須です。')
	  			}
	  			if(currentLang=='cn')
	  			{
	  				$('.contact-message').html('你的名字是必需的。')
	  			}
	  		}

	  		if(email)
	  		{
	  			if(email.length < 3){
	  				$('.email').addClass('border-danger'); valid=false;
		  			if(currentLang=='en')
		  			{
		  				$('.contact-message').html('Your email must have a minimum of 3 characters.')
		  			}
		  			if(currentLang=='jp')
		  			{
		  				$('.contact-message').html('メールアドレスは 3 文字以上である必要があります。')
		  			}
		  			if(currentLang=='cn')
		  			{
		  				$('.contact-message').html('您的电子邮件必须至少包含 3 个字符。')
		  			}
		  		}
		  		else if(email.length >100){
		  			$('.email').addClass('border-danger'); valid=false;
		  			if(currentLang=='en')
		  			{
		  				$('.contact-message').html('Your email must have a maximum of 100 characters.')
		  			}
		  			if(currentLang=='jp')
		  			{
		  				$('.contact-message').html('電子メールは最大 100 文字にする必要があります。')
		  			}
		  			if(currentLang=='cn')
		  			{
		  				$('.contact-message').html('您的电子邮件不得超过 100 个字符。')
		  			}
		  		}
		  		else if(!testEmail.test(email)){
		  			$('.email').addClass('border-danger'); valid=false;
		  			if(currentLang=='en')
		  			{
		  				$('.contact-message').html('Your email is invalid')
		  			}
		  			if(currentLang=='jp')
		  			{
		  				$('.contact-message').html('あなたのメールアドレスは無効です')
		  			}
		  			if(currentLang=='cn')
		  			{
		  				$('.contact-message').html('您的邮箱无效')
		  			}
		  		}
	  		}
	  		else{
	  			$('.email').addClass('border-danger'); valid=false;
	  			if(currentLang=='en')
	  			{
	  				$('.contact-message').html('Your email is required.')
	  			}
	  			if(currentLang=='jp')
	  			{
	  				$('.contact-message').html('あなたのメールアドレスは必須です')
	  			}
	  			if(currentLang=='cn')
	  			{
	  				$('.contact-message').html('您的电子邮件是必需的。')
	  			}
	  		}

	  		if(phone)
	  		{
	  			if(phone.length<8){
	  				$('.phone').addClass('border-danger'); valid=false;
		  			if(currentLang=='en')
		  			{
		  				$('.contact-message').html('Your phone is invalid.')
		  			}
		  			if(currentLang=='jp')
		  			{
		  				$('.contact-message').html('あなたの電話は無効です。')
		  			}
		  			if(currentLang=='cn')
		  			{
		  				$('.contact-message').html('您的电话无效。')
		  			}
		  		}
		  		else if(phone.length >12){
		  			$('.phone').addClass('border-danger'); valid=false;
		  			if(currentLang=='en')
		  			{
		  				$('.contact-message').html('Your phone is invalid.')
		  			}
		  			if(currentLang=='jp')
		  			{
		  				$('.contact-message').html('あなたの電話は無効です。')
		  			}
		  			if(currentLang=='cn')
		  			{
		  				$('.contact-message').html('您的电话无效。')
		  			}
		  		}
	  		}
	  		else{
	  			$('.phone').addClass('border-danger'); valid=false;
	  			if(currentLang=='en')
	  			{
	  				$('.contact-message').html('Your phone is required.')
	  			}
	  			if(currentLang=='jp')
	  			{
	  				$('.contact-message').html('電話番号が必要です。')
	  			}
	  			if(currentLang=='cn')
	  			{
	  				$('.contact-message').html('您的电话是必需的。')
	  			}
	  			
	  		}

	  		if(message)
	  		{
	  			if(message.length<3){
	  				$('.message-box').addClass('border-danger'); valid=false;
		  			if(currentLang=='en')
		  			{
		  				$('.contact-message').html('Your message must have a minimum of 3 characters.')
		  			}
		  			if(currentLang=='jp')
		  			{
		  				$('.contact-message').html('メッセージには少なくとも 3 文字を含める必要があります。')
		  			}
		  			if(currentLang=='cn')
		  			{
		  				$('.contact-message').html('您的消息必须至少包含 3 个字符。')
		  			}
		  		}
		  		else if(message.length >1000){
		  			$('.message-box').addClass('border-danger'); valid=false;
		  			if(currentLang=='en')
		  			{
		  				$('.contact-message').html('Your message must have a maximum of 1000 characters.')
		  			}
		  			if(currentLang=='jp')
		  			{
		  				$('.contact-message').html('メッセージは最大 1000 文字にする必要があります。')
		  			}
		  			if(currentLang=='cn')
		  			{
		  				$('.contact-message').html('您的消息不得超过 1000 个字符。')
		  			}
		  		}
	  		}
	  		else{
	  			$('.message-box').addClass('border-danger'); valid=false;
	  			if(currentLang=='en')
	  			{
	  				$('.contact-message').html('Your message is required.')
	  			}
	  			if(currentLang=='jp')
	  			{
	  				$('.contact-message').html('メッセージは必須です。')
	  			}
	  			if(currentLang=='cn')
	  			{
	  				$('.contact-message').html('您的消息是必需的。')
	  			}
	  		}
		  		
		  	if(valid==true && isSubmit==false)
		  	{
		  		$('.contact-message').html('');
		  		$('#submit-contact').prop('disabled',true)
		  		isSubmit=true;
		  		var request = $.ajax({
				  url: "/mailer/index.php",
				  method: "POST",
				  data: { name : name,phone:phone,email:email,message:message,lang:currentLang},
				  dataType: "html"
				});
				request.done(function( msg ) {
				 $('#form-contact')[0].reset()
				  $('.res-message').html(msg)
				  $('#submit-contact').prop('disabled',false);
				  isSubmit=false;
				});
				 
				request.fail(function( jqXHR, textStatus ) {
				  $('.res-message').html(textStatus);
				  $('#submit-contact').prop('disabled',false);
				  isSubmit=false;
				});
		  	}
		});
})