/* ==========================================================================
Demo functions
========================================================================== */

"use strict";

function initDemo() {
  //Code highlight init
  $("pre code").each(function (i, block) {
    hljs.highlightBlock(block);
  });

  //Scrollspy nav init
  if ($("#scrollnav").length) {
    var sticky = new Waypoint.Sticky({
      element: $("#scrollnav")[0],
    });
    $(".scrollnav-tabs").scrollspy({
      offset: -25,
      activeClass: "is-active",
    });
  }

  //Show and hide code accordion
  $(".accordion-wrapper .trigger a").on("click", function () {
    $(this).html(
      '<i class="im im-icon-Coding is-icon-2x"></i> HIDE CODE<i class="im im-icon-Add"></i>'
    );
    $(".trigger.active a").html(
      '<i class="im im-icon-Coding is-icon-2x"></i> SHOW CODE<i class="im im-icon-Add"></i>'
    );
  });

  //Animated Vivus svg hero illustrations for component pages
  if ($("#buttons").length) {
    new Vivus("buttons", {
      duration: 300,
      file: "assets/img/graphics/components/buttons.svg",
    });
  }
  if ($(".slick-gallery").length) {
      $(".slick-gallery").slick({
        slidesToShow: 6,
        slidesToScroll: 3,
        arrows: false,
        dots: true,
        cssEase: "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
        autoplay: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1180,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 2
            }
          }
        ]
      });
  }
  //Navbar styles panel

}
